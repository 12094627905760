@import './buttons';
@import './nprogress.css';
@import './wrappers.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* :root {
  --brand-primary: #131313;
  --brand-primary-light: #777777;
  --brand-primary-lighter: #a9a9a9;
  --brand-primary-dark: #191919;
  --brand-primary-darker: #000000;
  --brand-secondary: #940911;
  --brand-secondary-light: #cc0000;
  --brand-secondary-dark: #8c1717;
} */

html,
body {
  padding: 0;
  margin: 0;
  font-family: DINPro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  background-color: #000;
  color: #e4e4e4;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button {
  outline: none;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

li {
  list-style: none;
}

.logo_section {
  overflow: hidden;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* stylings of react-carousel  */

.carousel-slider {
  overflow: visible !important;
}

.slider-wrapper {
  overflow: visible !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background-color: #950810 !important;
}

.carousel .control-dots .dot {
  background: none !important;
  border: 2px solid #950810 !important;
}

.carousel .control-dots .dot {
  background-color: none !important;
}

.carousel button {
  min-width: 32px;
  /* position: relative; */
}

.carousel .control-prev.control-arrow {
  left: 10px !important;
}

.carousel .control-next.control-arrow {
  right: 10px !important;
}

.carousel .control-dots .dot {
  width: 12px;
  height: 12px;
}

.carousel .control-prev.control-arrow:before {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 7px;
  height: 7px;
  border-top: 3px solid white !important;
  border-left: 3px solid white !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  transform: rotate(-45deg) !important;
  margin: 0 auto !important;
  margin-left: 7.5px !important;
}

.carousel .control-next.control-arrow:before {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 7px;
  height: 7px;
  border-top: 3px solid white !important;
  border-left: 3px solid white !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  transform: rotate(135deg) !important;
  margin: 0 auto !important;
  margin-right: 7.5px !important;
}

.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;

  background: #242424 !important;
  border-radius: 100%;
  max-height: 32px;
  max-width: 32px !important;
  width: 32px !important;
  top: 50% !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

.ReactModal__Overlay {
  background: rgb(0 0 0 / 52%) !important;
}

.slick-prev:before {
  color: black !important;
  width: 10px !important;
  height: 10px !important;
  border-top: 3px solid white !important;
  border-left: 3px solid white !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  content: '' !important;
  display: inline-block !important;
  margin: 0 5px;
  transform: rotate(-45deg) !important;
}

.slick-next:before {
  color: black !important;
  width: 10px !important;
  height: 10px !important;
  border-top: 3px solid white !important;
  border-left: 3px solid white !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  content: '' !important;
  display: inline-block !important;
  margin: 0 5px;
  transform: rotate(135deg) !important;
}

.images_gallery_mobile .carousel .control-prev.control-arrow {
  left: 10px !important;
}

.images_gallery_mobile .carousel .control-next.control-arrow {
  right: 10px !important;
}

body.customize-page-product #front-chat-container .fc-3I534 {
  bottom: 74px !important;
}

#front-chat-container .fc-3I534 {
  background-color: #950810 !important;
  height: 58px !important;
  width: 58px !important;
}

body.product-page-chat #front-chat-container .fc-3I534 {
  bottom: 74px !important;
}

body #front-chat-container .fc-3I534 {
  position: absolute !important;
  right: 0 !important;
  bottom: 0px;
}

@media screen and (max-width: 1022px) {
  body.customize-page-product #front-chat-container .fc-3I534 {
    bottom: 135px !important;
  }

  body.product-page-chat #front-chat-container .fc-3I534 {
    bottom: 115px !important;
  }
}

@media screen and (max-width: 768px) {
  .slick-prev {
    left: 10px !important;
    z-index: 4 !important;
  }

  .slick-next {
    right: 10px !important;
  }
}

@media screen and (max-width: 426px) {
  .gaming_product_list .carousel .control-prev.control-arrow {
    left: 10px !important;
  }

  .gaming_product_list .carousel .control-next.control-arrow {
    right: 10px !important;
  }

  .carousel.carousel-slider .control-arrow {
    top: 45% !important;
  }
}

@media screen and (max-width: 360px) {
  .carousel.carousel-slider .control-arrow {
    top: 40% !important;
  }
}

.testimonial-slides .slick-slide {
  opacity: 0.4;
}

.testimonial-slides .slick-current {
  opacity: 1;
}

@media (min-width: 1024px) {
  .testimonial-slides .slick-prev {
    background-color: #950810;
    height: 48px;
    width: 48px;
    z-index: 1;
    padding-right: -16px;
  }

  .testimonial-slides .slick-next {
    background-color: #950810;
    height: 48px;
    width: 48px;
    z-index: 1;
    padding-left: -16px;
  }
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('/fonts/Roboto-Medium.ttf');
  font-style: normal;
	font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('/fonts/Roboto-Medium.ttf');
  font-style: normal;
  font-weight: 600;
	font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('/fonts/Roboto-Bold.ttf');
  font-style: normal;
  font-weight: 700;
	font-display: swap;
}

@font-face {
  font-family: 'Roboto-Normal';
  src: url('/fonts/Roboto-Regular.ttf');
  font-weight: 500;
  font-style: normal;
	font-display: swap;
}

@font-face {
  font-family: 'big_noodle_titling';
  src: url('/fonts/big_noodle_titling.ttf');
  font-style: normal;
	font-display: swap;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('/fonts/Roboto-Light.ttf');
  font-style: 300;
	font-display: swap;
}

@font-face {
  font-family: 'Eurostile-Oblique';
  src: url('/fonts/Eurostile-Oblique.woff');
	font-display: swap;
}

@font-face {
  font-family: 'Eurostile-Bold';
  src: url('/fonts/EurostileBold.woff');
  font-style: 300;
	font-display: swap;
}

@font-face {
  font-family: 'Eurostile-Normal';
  src: url('/fonts/EuroStyleNormal.woff');
  font-style: 300;
	font-display: swap;
}

/* .fixed_position .top-level > a {
  padding: 0 34px !important;
} */

#front-chat-container .fc-3xgLq {
  height: 32px !important;
  width: 32px !important;
}

/* 
.fc-3_a5O {
  background-color: #950810 !important;
} */

#front-chat-container div[role='button'] {
  bottom: 100px;
}

.glow {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0;
  display: block;
  margin: 0;
  color: #2dccd3;
  /* Clip Background Image */

  background: url('https://cdn.shopify.com/s/files/1/0637/0407/2436/files/fill.png?v=1714058551') repeat-y;
  -webkit-background-clip: text;
  background-clip: text;

  /* Animate Background Image */

  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 50s linear infinite;

  /* Activate hardware acceleration for smoother animations */

  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.glow2 {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
  display: block;
  margin: 0;
  /* Clip Background Image */

  /* background: url('https://cdn.shopify.com/s/files/1/0637/0407/2436/files/fill2.png?v=1714058551') repeat-y;
  -webkit-background-clip: text;
  background-clip: text; */

  /* Animate Background Image */

  /* -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 50s linear infinite; */

  /* Activate hardware acceleration for smoother animations */

  /* -webkit-transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden; */
}

/* Animate Background Image */

@keyframes aitf {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

#system_carousel {
  width: 100%;
}

#system_carousel .slick-track {
  display: flex;
  gap: 32px;
  justify-content: space-between;
}

#system_carousel .slick-list {
  max-width: 1088px;
  margin: 0 auto;
}

#system_carousel .slick-dots li {
  margin: 0;
}

#system_carousel .slick-dots {
  height: 4px;
}

#small-reviews-carousel .slick-track {
  display: flex;
  gap: 38px;
}

#small-reviews-carousel .slick-slide {
  width: 192px;
}

#large-reviews-carousel .slick-track {
  display: flex;
  gap: 42px;
}

#large-reviews-carousel .slick-slide {
  width: 304px;
}

@media (max-width: 640px) {
  #system_carousel .slick-track {
    gap: 32px;
    width: 100% !important;
  }

  #small-reviews-carousel .slick-slide {
    width: 251px;
  }
}

@media (width<=480px) {
  #system_carousel .slick-track {
    gap: 0px;
  }

  #system_carousel .slick-slide:not(.slick-active) {
    opacity: 0.4;
  }
}

.glow {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0;
  display: block;
  margin: 0;
  /* Clip Background Image */

  background: url('https://cdn.shopify.com/s/files/1/0637/0407/2436/files/fill.png?v=1714058551') repeat-y;
  -webkit-background-clip: text;
  background-clip: text;

  /* Animate Background Image */

  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 50s linear infinite;

  /* Activate hardware acceleration for smoother animations */

  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.glow2 {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
  display: block;
  margin: 0;
  color: #2dccd3;
  /* Clip Background Image */

  /* background: url('https://cdn.shopify.com/s/files/1/0637/0407/2436/files/fill2.png?v=1714058551') repeat-y;
  -webkit-background-clip: text;
  background-clip: text; */

  /* Animate Background Image */

  /* -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 50s linear infinite; */

  /* Activate hardware acceleration for smoother animations */
  /* 
  -webkit-transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden; */
}

/* Animate Background Image */

@keyframes aitf {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}
.zopim {
  display: none !important;
}
.zopim:first-of-type {
  display: none !important;
}
/* If pointer: coarse user is on mobile and the chat widget opens in new window rather than in same browser tab,
   meaning that we can turn off both the small and large chat widgets on mobile, but on browser we only need to turn off 
   the first element with className of zopim (the small widget) */
/* @media (pointer: coarse) {
  .zopim {
    display: none !important;
  }
}

.zopim:first-of-type {
  display: none !important;
} */

.uploadcare--widget__button.uploadcare--widget__button_type_open {
  color: white;
  border: none;
  background: #950810;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Roboto-Medium';
  outline: none;
}

.uploadcare--widget__text {
  font-size: 12px;
}

.uploadcare--widget__button_type_cancel {
  display: none;
  background: green;
  color: #fff;
  font: inherit;
  box-shadow: none;
  text-shadow: inherit;
}

.uploadcare--progress_type_canvas {
  padding: 2px;
  width: 32px;
  height: 32px;
  color: #8c080f;
  border-color: #e3e1e1;
}

@media (width<=480px) {
  .uploadcare--widget__button.uploadcare--widget__button_type_open {
    font-size: 11px;
  }
  .uploadcare--widget__text {
    font-size: 11px;
  }
}

.uploadcare--button_primary:focus, .uploadcare--button_primary:hover {
  background: #7b0007;
  border-color: #7b0007;
  color: #fff;
}

.uploadcare--button_primary {
  background: #950810;
  border-color: #950810;
  color: #fff;
}

/* turn the "after" pseudo element of the dialog container's div into a hint */
.info-text {
  margin: 2em;
  color: #950810;
  font-size: 13px;
  font-family: 'Roboto-Medium';
  margin-bottom: 0;
}

.crop-text {
  margin: 2em;
  color: #950810;
  font-size: 11px;
  font-family: 'Roboto-Medium';
  margin: 0;
}

.drag-drop-text {
  color: #353535;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Arial,sans-serif;
  font-weight: 400;
  line-height: 45px;
  font-size: 42.5px;
  margin: 0 0 10px;
}

.or-text {
  font-size: 15px;
  line-height: 20px;
  color: #a4a2a1;
  padding: 0;
  text-align: center;
}

.modal-btn-close {
  position: absolute;
  right: 20px;
  background-color: #950810;
  border: 1px solid #950810;
  padding: 5px;
  border-radius: 3px;
  color: white;
}

.modal-header {
  margin: 0;
  font-family: 'big_noodle_titling';
  font-size: 24px;
  width: 100%;
}

.modal-location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  flex-direction: column;
}

.location-prompt {
  text-align: center;
}

.modal-btn-location {
  margin-top: 7%;
  height: 35px;
  padding: 10px;
  background-color: #950810;
  border: 1px solid #950810;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}
