button {
  cursor: pointer;
}

.button {
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: #000;
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.button-div {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  min-width: auto;
  border: none;
  color: #fff;
  outline: none;
}

.button--submit {
  width: 100%;
  color: white;
  background: #950810;
  font-family: 'Roboto-Medium';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding: 12px 20px;
  border-radius: 3px;
  border: none;
  outline: 0;
}

.button--disabled {
  opacity: 0.4;
}
.button--submit:hover {
  background: #800006;
}

.button--disabled:hover {
  opacity: 0.4;
}

.button--link {
  background-color: transparent;
  border: none;
  height: 24px;
  color: #e4e4e4;
  font-family: 'Roboto-Medium';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: underline;
}
.button--link:hover {
  color: #fd0300;
}
