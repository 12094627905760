.modalOverlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  inset: 0px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  max-width: 560px;
  width: 100%;
}
